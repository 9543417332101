import axios from '@/http';

export default {
  /**
   * Method used to list bundles.
   *
   * @returns {Promise} The result in a promise.
   */
  get() {
    return axios.get('/bundles');
  },

  /**
   * Method used to create a bundle.
   *
   * @returns {Promise} The result in a promise.
   */
  create(payload) {
    return axios.post('/bundles', payload);
  },

  /**
   * Method used to update a bundle.
   *
   * @returns {Promise} The result in a promise.
   */
  update(payload) {
    return axios.put('/bundles', payload);
  },

  /**
   * Method used to get a bundle.
   *
   * @returns {Promise} The result in a promise.
   */
  getId(id) {
    return axios.get(`/bundles/${id}`);
  },

  /**
   * Method used to delete a bundle.
   *
   * @returns {Promise} The result in a promise.
   */
  delete(id) {
    return axios.delete(`/bundles/${id}`);
  },
};
