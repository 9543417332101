/* ============
 * Auth mutations
 * ============
 *
 * The mutations available for the auth module.
 */

import axios from '@/http';
import ability from '@/plugins/ability';
import * as types from './types';

export default {
  [types.LOGIN](state, payload) {
    state.account = payload.account;
    axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
  },

  [types.SET_ABILITY](state, payload) {
    state.ability = payload.filter((perm) => perm.isEnabled);
    ability.update(state.ability);
  },

  [types.LOGOUT](state) {
    state.account = false;
    delete axios.defaults.headers.common.Authorization;
  },
};
