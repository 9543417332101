import axios from '@/http';

export default {
  /**
   * Method used to create a company account.
   *
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  create(payload) {
    return axios.post('/company', payload);
  },

  /**
   * Method used to update a company.
   *
   * @param {String} company The company id.
   * @param {Array} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  update(company, payload) {
    return axios.patch(`/companies/${company}`, payload);
  },

  /**
   * Method used to get company details.
   *
   * @param {Object} company The company id.
   *
   * @returns {Promise} The result in a promise.
   */
  get(company) {
    return axios.get(`/companies/${company}`);
  },

  /**
   * Method used to delete a company.
   *
   * @param {Object} company The company id.
   *
   * @returns {Promise} The result in a promise.
   */
  delete(company) {
    return axios.delete(`/companies/${company}`);
  },

  /**
   * Method used to get all companies.
   *
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getAll(filter) {
    return axios.post('/companies', filter);
  },

  /**
   * Method used to get all companies.
   *
   * @param {String} company The company id.
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getAccounts(company, filter) {
    return axios.post(`/companies/${company}/accounts`, filter);
  },

  /**
   * Method used to add an user to a company.
   *
   * @param {String} company The company id.
   * @param {Object} user The user.
   *
   * @returns {Promise} The result in a promise.
   */
  addUser(company, user) {
    return axios.post(`/companies/${company}/users`, user);
  },

  /**
   * Method used to delete an user to a company.
   *
   * @param {String} company The company id.
   * @param {String} user The user id.
   *
   * @returns {Promise} The result in a promise.
   */
  updateUser(company, user, payload) {
    return axios.patch(`/companies/${company}/users/${user}`, payload);
  },

  /**
   * Method used to delete an user to a company.
   *
   * @param {String} company The company id.
   * @param {String} user The user id.
   *
   * @returns {Promise} The result in a promise.
   */
  deleteUser(company, user) {
    return axios.delete(`/companies/${company}/users/${user}`);
  },

  /**
   * Method used to add an user to a company.
   *
   * @param {String} company The company id.
   * @param {String} user The user id.
   *
   * @returns {Promise} The result in a promise.
   */
  inviteUser(company, user) {
    return axios.post(`/companies/${company}/users/${user}/invite`);
  },
};
