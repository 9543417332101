/* ============
 * Application mutations
 * ============
 *
 * The mutations available for the application module.
 */

import * as types from './types';

export default {
  [types.ADD_NOTIFICATION](state, payload) {
    state.notifications.push(payload);
  },

  [types.REMOVE_NOTIFICATION](state, index) {
    state.notifications.splice(index, 1);
  },

  [types.PUSH_NAVIGATION](state, nav) {
    state.navigation.splice(nav.meta.breadcrumb);
    state.navigation[nav.meta.breadcrumb] = nav;
  },

  [types.POP_NAVIGATION](state) {
    state.navigation.pop();
  },

  [types.RESET_NAVIGATION](state) {
    state.navigation = [{ name: 'Home', path: '/', meta: { title: 'Accueil' } }];
  },

  [types.BACK_NAVIGATION](state, index) {
    state.navigation.length = index;
  },

  [types.SET_VERSION](state, version) {
    state.version = version;
  },
};
