/* ============
 * Company state
 * ============
 *
 * The initial state of the company module.
 */

export default {
  all: [],
  accounts: [],
  company: {},
  filters: [
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'name',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'users',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'apiContactMail',
    },
  ],
};
