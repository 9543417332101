/* ============
 * ApiAccount state
 * ============
 *
 * The initial state of the apiAccount module.
 */

const filters = [
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'name',
  },
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'companyName',
  },
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'clientId',
  },
  {
    criteria: {
      type: 'ListStringValue',
      values: ['ISV', 'Partner', 'Silae'],
    },
    name: 'type',
  },
  {
    criteria: {
      type: 'ListStringValue',
      values: ['enabled', 'disabled', 'notActivated', 'error'],
    },
    name: 'status',
  },
  {
    criteria: {
      type: 'DateRange',
      min: '',
      max: '',
    },
    name: 'creationDate',
  },
  {
    criteria: {
      type: 'DateRange',
      min: '',
      max: '',
    },
    name: 'modificationDate',
  },
  {
    criteria: {
      type: 'DateRange',
      min: '',
      max: '',
    },
    name: 'primarySecretExpirationDate',
  },
  {
    criteria: {
      type: 'DateRange',
      min: '',
      max: '',
    },
    name: 'secondarySecretExpirationDate',
  },
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'platform',
  },
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'entity',
  },
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'domain',
  },
  {
    criteria: {
      type: 'StringValue',
      value: '',
    },
    name: 'billingCode',
  },
  {
    criteria: {
      type: 'BooleanValue',
      value: false,
    },
    name: 'isTestAccount',
  },
];

export default {
  NO_SOFTWARE_OFFERS: [
    '8031dff4-f6f8-11ec-a34c-4180c0410450',
    '6bb4a789-ab8a-11ed-acc8-0242ac110002',
  ],
  all: [],
  offers: [],
  platforms: [],
  entities: [],
  domains: [],
  agencies: [],
  softwares: [],
  deleted: [],
  filters: {
    model: JSON.parse(JSON.stringify(filters)),
    default: JSON.parse(JSON.stringify(filters)),
  },
};
