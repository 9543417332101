/* ============
 * Account state
 * ============
 *
 * The initial state of the account module.
 */

export default {
  account: {},
  subscriptions: [],
  subscription: {},
  bundles: {
    default: [],
    offer: [],
    all: [],
  },
};
