/* ============
 * Account actions
 * ============
 *
 * The actions available for the account module.
 */

import Api from '@/api/Account';
import ApiOffer from '@/api/OfferType';
import ApiBundle from '@/api/Bundle';
import * as types from './types';

export default {
  /**
   * Action which will get infos about an account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} rootState Global state of the store.
   * @param {Object} account The account type and id.
   *
   * @returns {Promise} The result in a promise.
   */
  get({ commit, rootState }, account) {
    return new Api(
      account && account.type ? account.type : rootState.auth.account.type,
      account && account.id ? account.id : rootState.auth.account.id,
    )
      .get()
      .then((data) => {
        commit(types.SET_ACCOUNT, data.data);
        return data;
      });
  },

  /**
   * Action which will get subscriptions of an account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} rootState Global state of the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptions({ commit, rootState }, payload) {
    return new Api(
      payload.account && payload.account.type ? payload.account.type : rootState.auth.account.type,
      payload.account && payload.account.id ? payload.account.id : rootState.auth.account.id,
    )
      .getSubscriptions(payload.request)
      .then((data) => {
        commit(types.SET_SUBSCRIPTIONS, data.data);
        return data;
      });
  },

  /**
   * Action which will get a subscription.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} rootState Global state of the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscription({ commit, rootState }, payload) {
    return new Api(
      payload.type ? payload.type : rootState.auth.account.type,
      payload.id ? payload.id : rootState.auth.account.id,
    )
      .getSubscription(payload.subscription)
      .then((data) => {
        commit(types.SET_SUBSCRIPTION, data.data);
        return data;
      });
  },

  /**
   * Action which will toggle subscription status.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} rootState Global state of the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  updateSubscription({ commit, dispatch, rootState }, payload) {
    return new Api(
      payload.account && payload.account.type ? payload.account.type : rootState.auth.account.type,
      payload.account && payload.account.id ? payload.account.id : rootState.auth.account.id,
    )
      .updateSubscription(payload.subscription)
      .then((data) => {
        commit(types.SET_SUBSCRIPTION, data.data);
        dispatch(
          'application/addNotification',
          {
            content: 'Vous avez modifié cet accès !',
            color: 'success',
          },
          { root: true },
        );
        return data;
      });
  },

  /**
   * Action which will generate account's secret.
   *
   * @param {Object} rootState Global state of the store.
   * @param {String} type The secret type
   *
   * @returns {Promise} The result in a promise.
   */
  generateSecret({ rootState }, payload) {
    return new Api(
      payload.account && payload.account.type ? payload.account.type : rootState.auth.account.type,
      payload.account && payload.account.id ? payload.account.id : rootState.auth.account.id,
    ).generateSecret(payload.secretType);
  },

  /**
   * Action which will generate subscription's sub key.
   *
   * @param {Object} rootState Global state of the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  generateKey({ rootState }, payload) {
    return new Api(
      payload.account && payload.account.type ? payload.account.type : rootState.auth.account.type,
      payload.account && payload.account.id ? payload.account.id : rootState.auth.account.id,
    ).generateKey(payload.subscription.id, payload.subscription.type);
  },

  /**
   * Action which will get default bundles of an account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} rootState Global state of the store.
   * @param {Object} account The account type and id.
   *
   * @returns {Promise} The result in a promise.
   */
  getBundlesDefault({ commit, rootState }, account) {
    return new Api(
      account && account.type ? account.type : rootState.auth.account.type,
      account && account.id ? account.id : rootState.auth.account.id,
    )
      .getBundles()
      .then((data) => {
        commit(types.SET_BUNDLES_DEFAULT, data.data);
        return data;
      });
  },

  /**
   * Action which will get bundles of an offer.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} offer The offer id.
   *
   * @returns {Promise} The result in a promise.
   */
  getBundlesOffer({ commit }, offer) {
    return ApiOffer.getBundles(offer).then((data) => {
      commit(types.SET_BUNDLES_OFFER, data.data);
      return data;
    });
  },

  /**
   * Action which will get default bundles of an account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} rootState Global state of the store.
   * @param {Object} account The account type and id.
   *
   * @returns {Promise} The result in a promise.
   */
  getBundlesAll({ commit }) {
    return ApiBundle.get().then((data) => {
      commit(types.SET_BUNDLES_ALL, data.data);
      return data;
    });
  },

  /**
   * Action which will update an API account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} rootState Global state of the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  update({ commit, dispatch, rootState }, payload) {
    return new Api(
      payload.account && payload.account.type ? payload.account.type : rootState.auth.account.type,
      payload.account && payload.account.id ? payload.account.id : rootState.auth.account.id,
    )
      .update(payload.patch)
      .then((data) => {
        commit(types.SET_ACCOUNT, data.data);
        dispatch(
          'application/addNotification',
          {
            content: 'Vous avez modifié ce compte !',
            color: 'success',
          },
          { root: true },
        );
        return data;
      });
  },

  /**
   * Action which will update the bundles of an account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} rootState Global state of the store.
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  updateBundles({ commit, rootState, dispatch }, payload) {
    return new Api(
      payload.account && payload.account.type ? payload.account.type : rootState.auth.account.type,
      payload.account && payload.account.id ? payload.account.id : rootState.auth.account.id,
    )
      .updateBundles(payload.bundles)
      .then((data) => {
        commit(types.SET_ACCOUNT, data.data);
        dispatch(
          'application/addNotification',
          {
            content: 'Vous avez modifié les bundles activés !',
            color: 'success',
          },
          { root: true },
        );
        return data;
      });
  },
};
