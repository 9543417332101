/* ============
 * Auth getters
 * ============
 *
 * The getters available for the auth module.
 */

export default {
  isLogged: (state) => !!state.account,
  level: (state) => {
    if (state.account.type === 'Admin') return 3;
    if (state.account.type === 'SupportReadOnlyAdmin') return 2;
    return +state.ability.some((perm) => perm.action === 'update' && perm.subject === 'company');
  },
};
