/* ============
 * Account getters
 * ============
 *
 * The getters available for the account module.
 */

export default {
  get: (state) => state.account,
  getSubscriptions: (state) => state.subscriptions,
  getSubscription: (state) => state.subscription,
  getBundlesFront: (state) => state.bundles.offer.map((offer) => ({
    ...offer,
    enabled: state.bundles.default.findIndex((def) => def.id === offer.id) !== -1,
  })),
  getBundlesBack: (state) => state.bundles.all.map((all) => ({
    ...all,
    available: state.bundles.offer.findIndex((offer) => offer.id === all.id) !== -1,
    enabled: state.bundles.default.findIndex((def) => def.id === all.id) !== -1,
  })),
};
