/* ============
 * ApiAccount actions
 * ============
 *
 * The actions available for the apiAccount module.
 */

import api from '@/api/ApiAccount';
import apiOffer from '@/api/OfferType';
import apiPlatform from '@/api/Platform';
import * as types from './types';

export default {
  /**
   * Action which will get all API accounts.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} filter The filter to apply.
   *
   * @returns {Promise} The result in a promise.
   */
  get({ commit }, filter) {
    return api.get(filter).then((data) => {
      commit(types.SET_ALL, data.data);
      return data;
    });
  },

  /**
   * Action which will get all offers for a certain type.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} type The type of the offers.
   *
   * @returns {Promise} The result in a promise.
   */
  getOffers({ commit }, type) {
    return apiOffer.get(type).then((data) => {
      commit(types.SET_OFFERS, data.data);
      return data;
    });
  },

  /**
   * Action which will get all platforms.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  getPlatforms({ commit }) {
    return apiPlatform.get().then((data) => {
      commit(types.SET_PLATFORMS, data.data);
      return data;
    });
  },

  /**
   * Action which will get all domains of a platform.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} platform The platform id.
   *
   * @returns {Promise} The result in a promise.
   */
  getEntities({ commit }, platform) {
    return apiPlatform.getEntities(platform).then((data) => {
      commit(types.SET_ENTITIES, data.data);
      return data;
    });
  },

  /**
   * Action which will get all domains of a platform.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  getDomains({ commit }, payload) {
    return apiPlatform.getDomains(payload).then((data) => {
      commit(types.SET_DOMAINS, data.data);
      return data;
    });
  },

  /**
   * Action which will get all agencies of a domain.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  getAgencies({ commit }, payload) {
    return apiPlatform.getAgencies(payload).then((data) => {
      commit(types.SET_AGENCIES, data.data);
      return data;
    });
  },

  /**
   * Action which will get all agencies of a domain.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  getSoftwares({ commit }) {
    return api.getSoftwares().then((data) => {
      commit(types.SET_SOFTWARES, data.data);
      return data;
    });
  },

  /**
   * Action which will create an API account.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   *
   * @returns {Promise} The result in a promise.
   */
  create({ dispatch }, payload) {
    return api.create(payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez créé le compte ${payload.name} !`,
          color: 'success',
          route: `/account/${data.data.type}/${data.data.id}`,
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will delete an API account.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} account The API account id.
   *
   * @returns {Promise} The result in a promise.
   */
  delete({ dispatch }, account) {
    return api.delete(account).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez supprimé le compte API avec succès !',
          color: 'success',
          route: '/accounts',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will hard delete an API account.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} account The API account id.
   *
   * @returns {Promise} The result in a promise.
   */
  hardDelete({ dispatch }, account) {
    return api.hardDelete(account).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez supprimé définitivement le compte API avec succès !',
          color: 'success',
          route: '/accounts',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will get all deleted accounts.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getDeleted({ commit }, filter) {
    return api.getDeleted(filter).then((data) => {
      commit(types.SET_DELETED, data.data);
      return data;
    });
  },

  /**
   * Action which will delete a subscription.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} account The API account id.
   *
   * @returns {Promise} The result in a promise.
   */
  deleteSubscription({ dispatch }, subscription) {
    return api.deleteSubscription(subscription).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez supprimé la configuration API avec succès !',
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },
};
