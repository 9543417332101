import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    common: {
      accept: 'application/json',
    },
  },
});

export default http;
