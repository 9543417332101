import axios from '@/http';

export default {
  /**
   * Method used to list offer types.
   *
   * @param {String} type The type (ISV|Partner|Silae).
   *
   * @returns {Promise} The result in a promise.
   */
  get(type) {
    return axios.get('/offer-types', {
      params: {
        AccountType: type,
      },
    });
  },

  /**
   * Method used to create offers.
   *
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  create(payload) {
    return axios.post('/offer-types', payload);
  },

  /**
   * Method used to delete an offer.
   *
   * @param {String} offerType The id of the offer.
   *
   * @returns {Promise} The result in a promise.
   */
  delete(offerType) {
    return axios.delete(`/offer-types/${offerType}`);
  },

  /**
   * Method used to update an offer.
   *
   * @param {Object} payload The offer updated.
   *
   * @returns {Promise} The result in a promise.
   */
  update(payload) {
    return axios.put('/offer-types', payload);
  },

  /**
   * Method used to get details of offers.
   *
   * @param {String} offerType The offer type id.
   *
   * @returns {Promise} The result in a promise.
   */
  getDetails(offerType) {
    return axios.get(`/offer-types/${offerType}`);
  },

  /**
   * Method used to list bundles of an offer.
   *
   * @param {String} offerType The offer type id.
   *
   * @returns {Promise} The result in a promise.
   */
  getBundles(offerType) {
    return axios.get(`/offer-types/${offerType}/bundles`);
  },

  /**
   * Method used to list accounts of an offer.
   *
   * @param {String} offerType The offer type id.
   *
   * @returns {Promise} The result in a promise.
   */
  getAccounts(offerType, payload) {
    return axios.post(`/offer-types/${offerType}/accounts`, payload);
  },

  /**
   * Method used to update bundles of an offer.
   *
   * @param {String} offerType The offer type id.
   * @param {Array} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  updateBundles(offerType, payload) {
    return axios.put(`/offer-types/${offerType}/bundles`, payload);
  },
};
