var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"avatar",style:({
    background: _setup.hashColor(_vm.username, 90, 50),
    width: `${_vm.size}px`,
    height: `${_vm.size}px`,
    color: _setup.hashColor(_vm.username, 90, 30),
    'font-size': `${_vm.size / 2.5}px`,
  })},[_vm._v(" "+_vm._s(_vm.username .replaceAll(/[^\w\s]/g, '') .split(' ') .map((c) => c[0]) .join('').slice(0, 3))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }