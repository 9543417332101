import axios from '@/http';

export default {
  /**
   * Method used to get back-end veresion.
   *
   * @returns {Promise} The result in a promise.
   */
  version() {
    return axios.get('/utilities/backend-version');
  },
};
