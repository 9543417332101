/* ============
 * Account state
 * ============
 *
 * The initial state of the account module.
 */

export default {
  offers: [],
  offer: undefined,
  bundles: [],
  bundle: {},
  filters: [
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'name',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'companyName',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'clientId',
    },
    {
      criteria: {
        type: 'ListStringValue',
        values: ['ISV', 'Partner', 'Silae'],
      },
      name: 'type',
    },
    {
      criteria: {
        type: 'ListStringValue',
        values: ['enabled', 'disabled', 'notActivated', 'error'],
      },
      name: 'status',
    },
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'creationDate',
    },
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'modificationDate',
    },
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'primarySecretExpirationDate',
    },
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'secondarySecretExpirationDate',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'platform',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'entity',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'domain',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'billingCode',
    },
    {
      criteria: {
        type: 'BooleanValue',
        value: false,
      },
      name: 'isTestAccount',
    },
  ],
  currentBundles: [],
  currentAccounts: [],
};
