import Vue from 'vue';
import EdocUi from '@silae/edoc-ui';
import { abilitiesPlugin } from '@casl/vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import App from './App.vue';
import router from './router';
import store from './store';
import ability from './plugins/ability';
import interceptorsSetup from './http/interceptors';
import validationSetup from './plugins/validation';
import { msalPlugin } from './plugins/msal';
import { msalInstance } from './plugins/msal/config';

Vue.use(msalPlugin, msalInstance);
Vue.use(EdocUi);
Vue.use(abilitiesPlugin, ability, { useGlobalProperties: true });
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

require('./assets/scss/main.scss');

Vue.config.productionTip = false;

interceptorsSetup();
validationSetup();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
