export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_CURRENT_PAGE_ACCOUNTS = 'SET_CURRENT_PAGE_ACCOUNTS';
export const SET_LIMIT_ACCOUNTS = 'SET_LIMIT_ACCOUNTS';
export const SET_API_CONTACT_MAIL = 'SET_API_CONTACT_MAIL';
export const SET_NAME = 'SET_NAME';
export const SET_SILAE_CLIENT_ID = 'SET_SILAE_CLIENT_ID';
export const SET_FILTERS = 'SET_FILTERS';
