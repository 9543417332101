/* ============
 * Company getters
 * ============
 *
 * The getters available for the company module.
 */

export default {
  get: (state) => state.company,
  getAll: (state) => state.all,
};
