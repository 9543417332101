import axios from '@/http';

class ClientAccount {
  /**
   * The constructor for the OrganizationApi.
   *
   * @param {String} type The type of the account (isv|partner|silae).
   * @param {String} id The  account id.
   */
  constructor(type, id) {
    this.type = type.toLowerCase();
    this.id = id;
  }

  /**
   * Method used to get details of an account.
   *
   * @returns {Promise} The result in a promise.
   */
  get() {
    return axios.get(`/${this.type}-accounts/${this.id}`);
  }

  /**
   * Method used to update details of an account.
   *
   * @param {Array} payload The payload.
   * @returns {Promise} The result in a promise.
   */
  update(payload) {
    return axios.patch(`/${this.type}-accounts/${this.id}`, payload);
  }

  /**
   * Method used to generate a client secret.
   *
   * @param {String} type The type of secret (primary|secondary).
   * @returns {Promise} The result in a promise.
   */
  generateSecret(type) {
    return axios.post(`/${this.type}-accounts/${this.id}/generate-client-secret`, { type });
  }

  /**
   * Method used to get a subscription.
   *
   * @param {String} subscription The subscription id.
   * @returns {Promise} The result in a promise.
   */
  getSubscription(subscription) {
    return axios.get(`/${this.type}-accounts/${this.id}/subscription/${subscription}`);
  }

  /**
   * Method used to create a subscription.
   *
   * @param {Object} payload The payload.
   * @returns {Promise} The result in a promise.
   */
  createSubscription(payload) {
    return axios.post(`/${this.type}-accounts/${this.id}/subscription`, payload);
  }

  /**
   * Method used to update a subscription.
   *
   * @param {Array} payload The payload.
   * @returns {Promise} The result in a promise.
   */
  updateSubscription(payload) {
    return axios.patch(`/${this.type}-accounts/${this.id}/subscription/${payload.id}`, payload.patch);
  }

  /**
   * Method used to generate a subscription key.
   *
   * @param {String} subscription The subscription id.
   * @param {String} type The type of secret (primary|secondary).
   * @returns {Promise} The result in a promise.
   */
  generateKey(subscription, type) {
    return axios.post(`/${this.type}-accounts/${this.id}/subscription/${subscription}/generate-sub-key`, { type });
  }

  /**
   * Method used to list subscriptions.
   *
   * @param {Array} payload The payload.
   * @returns {Promise} The result in a promise.
   */
  getSubscriptions(payload) {
    return axios.post(`/${this.type}-accounts/${this.id}/subscriptions`, payload);
  }

  /**
   * Method used to list default bundles of an account.
   *
   * @returns {Promise} The result in a promise.
   */
  getBundles() {
    return axios.get(`/${this.type}-accounts/${this.id}/bundles`);
  }

  /**
   * Method used to update default bundles of an account.
   *
   * @param {Array} payload The payload.
   * @returns {Promise} The result in a promise.
   */
  updateBundles(payload) {
    return axios.put(`/${this.type}-accounts/${this.id}/bundles`, payload);
  }

  /**
   * Method used to update bundles of a subscription.
   *
   * @param {Array} payload The payload.
   * @returns {Promise} The result in a promise.
   */
  updateSubscriptionBundles(id, payload) {
    return axios.put(`/${this.type}-accounts/${this.id}/subscription/${id}/bundles`, payload);
  }
}

export default ClientAccount;
