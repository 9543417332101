/* ============
 * Application state
 * ============
 *
 * The initial state of the application module.
 */

export default {
  notifications: [],
  navigation: [{ name: 'Home', path: '/', meta: { title: 'Accueil' } }],
  version: undefined,
};
