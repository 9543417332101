/* ============
 * Application actions
 * ============
 *
 * The actions available for the application module.
 */

import api from '@/api/Utilities';
import * as types from './types';

export default {
  /**
   * Action which will get infos about actual account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  addNotification({ commit }, payload) {
    commit(types.ADD_NOTIFICATION, payload);
    setTimeout(() => {
      commit(types.REMOVE_NOTIFICATION, 0);
    }, 5000);
  },

  async version({ commit }) {
    const version = await api.version();
    commit(types.SET_VERSION, version.data);
  },
};
