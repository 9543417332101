/* ============
 * Account actions
 * ============
 *
 * The actions available for the account module.
 */

import offerType from '@/api/OfferType';
import bundle from '@/api/Bundle';
import * as types from './types';

export default {
  /**
   * Action which will get all offers.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  getOffers({ commit }) {
    return offerType.get().then((data) => {
      commit(types.SET_OFFERS, data.data);
      return data;
    });
  },

  /**
   * Action which will get details about an offer.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  getDetails({ commit }, id) {
    return offerType.getDetails(id).then((data) => {
      commit(types.SET_OFFER, data.data);
      commit(types.SET_CURRENT_BUNDLES, JSON.parse(JSON.stringify(data.data.bundles)));
      return data;
    });
  },

  /**
   * Action which will get all bundles.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  getBundles({ commit }) {
    return bundle.get().then((data) => {
      commit(types.SET_BUNDLES, data.data);
      return data;
    });
  },

  /**
   * Action which will get all accounts linked.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  getAccounts({ commit }, { id, payload }) {
    return offerType.getAccounts(id, payload).then((data) => {
      commit(types.SET_CURRENT_ACCOUNTS, data.data);
      return data;
    });
  },

  /**
   * Action which will create a new offer.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  createOffer({ dispatch }, payload) {
    return offerType.create(payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez créé l'offre ${payload.name} !`,
          color: 'success',
          route: '/offers',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will update an offer.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  updateOffer({ dispatch }, payload) {
    return offerType.update(payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez modifié l'offre ${payload.name} !`,
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will delete an offer.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  deleteOffer({ dispatch }, payload) {
    return offerType.delete(payload.id).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez supprimé l'offre ${payload.name} !`,
          color: 'success',
          route: '/offers',
        },
        { root: true },
      );
      return data;
    });
  },
};
