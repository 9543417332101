import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_BACKEND_CLIENTID,
    authority: process.env.VUE_APP_BACKEND_AUTHORITY,
    knownAuthorities: [process.env.VUE_APP_BACKEND_KNOWNAUTHORITIES],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
