<template>
  <div
    class="avatar"
    :style="{
      background: hashColor(username, 90, 50),
      width: `${size}px`,
      height: `${size}px`,
      color: hashColor(username, 90, 30),
      'font-size': `${size / 2.5}px`,
    }"
  >
    {{
      username
        .replaceAll(/[^\w\s]/g, '')
        .split(' ')
        .map((c) => c[0])
        .join('').slice(0, 3)
    }}
  </div>
</template>
<script setup>
/* eslint-disable no-bitwise */

defineProps({
  username: { type: String, required: true },
  size: { type: Number, default: 64 },
});

function hashColor(string, saturation = 100, lightness = 75) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
}
</script>
<style scoped lang="scss">
.avatar {
  display: inline-flex;
  align-items: center;
  border-radius: 100%;
  user-select: none;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
