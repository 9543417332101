/* ============
 * ApiAccount getters
 * ============
 *
 * The getters available for the apiAccount module.
 */

export default {
  get: (state) => state.all,
  getOffers: (state) => state.offers,
  getPlatforms: (state) => state.platforms,
  getEntities: (state) => state.entities,
  getDomains: (state) => state.domains.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  }),
  getAgencies: (state) => state.agencies,
  getSoftwares: (state) => state.softwares.sort(),
};
