/* ============
 * Account mutations
 * ============
 *
 * The mutations available for the account module.
 */

import * as types from './types';

export default {
  [types.SET_OFFERS](state, payload) {
    state.offers = payload;
  },

  [types.SET_OFFER](state, payload) {
    state.offer = payload;
  },

  [types.SET_BUNDLES](state, payload) {
    state.bundles = payload;
  },

  [types.SET_BUNDLE](state, payload) {
    state.bundle = payload;
  },

  [types.SET_FILTERS](state, payload) {
    state.filters = payload;
  },

  [types.SET_CURRENT_BUNDLES](state, payload) {
    state.currentBundles = payload;
  },

  [types.SET_CURRENT_ACCOUNTS](state, payload) {
    state.currentAccounts = payload;
  },
};
