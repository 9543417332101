import Vue from 'vue';
import { EventMessageUtils, EventType, InteractionStatus } from '@azure/msal-browser';
import store from '@/store';

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
function accountArraysAreEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) return false;

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) return false;

    return (
      elementA.homeAccountId === elementB.homeAccountId
      && elementA.localAccountId === elementB.localAccountId
      && elementA.username === elementB.username
    );
  });
}

export const msalPlugin = {
  install: (app, msalInstance) => {
    const inProgress = InteractionStatus.Startup;
    const accounts = msalInstance.getAllAccounts();

    const state = {
      instance: msalInstance,
      inProgress,
      accounts,
    };

    Vue.prototype.$msal = state;

    const currentAccounts = msalInstance.getAllAccounts();

    msalInstance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.ACCOUNT_ADDED:
        case EventType.ACCOUNT_REMOVED:
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
            state.accounts = currentAccounts;
          }
          break;
        default:
      }

      const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
      if (status !== null) {
        state.inProgress = status;
      }
    });
  },
};

export function useMsal() {
  const internalInstance = Vue;
  if (!internalInstance) {
    throw Error('useMsal() cannot be called outside the setup() function of a component');
  }

  const { instance, accounts, inProgress } = internalInstance.prototype.$msal;

  if (!instance || !accounts || !inProgress) {
    throw Error('Please install the msalPlugin');
  }

  if (inProgress === InteractionStatus.Startup) {
    instance
      .handleRedirectPromise()
      .then((data) => {
        if (data) {
          console.debug(data);
          store.dispatch('auth/login', {
            token: data.idToken,
            account: {
              name: data.idTokenClaims.name,
              type: data.idTokenClaims.role,
              id: data.idTokenClaims.accountId,
              mail: data.idTokenClaims.email,
              company: data.idTokenClaims.companyId,
              expiration: data.idTokenClaims.exp * 1000,
            },
          });
        }
      })
      .catch(() => {
        instance.loginRedirect();
      });
  }

  return {
    instance,
    accounts,
    inProgress,
  };
}
