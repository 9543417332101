/* ============
 * Application getters
 * ============
 *
 * The getters available for the application module.
 */

export default {
  getNotifications: (state) => state.notifications,
};
