/* ============
 * Auth state
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  account: false,
  ability: [],
};
