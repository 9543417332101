/* ============
 * Auth actions
 * ============
 *
 * The actions available for the auth module.
 */

import api from '@/api/AbilityRule';
import * as types from './types';

export default {
  /**
   * Action which will get infos about an account.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  login({ commit, dispatch }, payload) {
    commit(types.LOGIN, payload);
    return api.get().then((data) => {
      commit(types.SET_ABILITY, data.data);
      dispatch('application/version', null, { root: true });
      return data;
    });
  },
};
