/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import { useMsal } from '@/plugins/msal';
import store from '@/store';
import eventBus from '@/plugins/eventBus';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/pages/Home.vue'),
      meta: {
        level: 0,
        breadcrumb: 0,
        title: 'Accueil',
      },
    },
    {
      path: '/companies',
      name: 'Companies',
      component: () => import('@/pages/list/Companies.vue'),
      meta: {
        level: 2,
        breadcrumb: 1,
        title: 'Liste des sociétés',
      },
    },
    {
      path: '/accounts',
      name: 'Accounts',
      component: () => import('@/pages/list/Accounts.vue'),
      meta: {
        level: 0,
        breadcrumb: 1,
        title: 'Liste des comptes',
      },
    },
    {
      path: '/offers',
      name: 'Offers',
      component: () => import('@/pages/list/Offers.vue'),
      meta: {
        level: 2,
        breadcrumb: 1,
        title: 'Liste des offres',
      },
    },
    {
      path: '/account/:type(Silae|ISV|Partner)?/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?',
      component: () => import('@/pages/details/Account.vue'),
      meta: {
        level: 0,
      },
      children: [
        {
          path: '',
          name: 'AccountInfos',
          component: () => import('@/pages/account/Infos.vue'),
          meta: {
            title: 'Informations générales',
            breadcrumb: 2,
          },
        },
        {
          path: 'subscriptions',
          name: 'AccountSubscriptions',
          component: () => import('@/pages/account/Subscriptions.vue'),
          meta: {
            title: "Configurations d'accès API",
            breadcrumb: 2,
          },
        },
        {
          path: 'subscription/:subscription([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
          name: 'AccountSubscription',
          component: () => import('@/pages/account/Subscription.vue'),
          meta: {
            title: "Configurations d'accès API",
            breadcrumb: 2,
          },
        },
        {
          path: 'parameters',
          name: 'AccountParameters',
          component: () => import('@/pages/account/Parameters.vue'),
          meta: {
            title: 'Paramètres',
            breadcrumb: 2,
          },
        },
        {
          path: 'secret',
          name: 'AccountSecret',
          component: () => import('@/pages/account/Secret.vue'),
          meta: {
            title: 'Gestion des ClientSecret',
            breadcrumb: 2,
          },
        },
      ],
    },
    {
      path: '/company/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
      component: () => import('@/pages/details/Company.vue'),
      meta: {
        level: 1,
      },
      children: [
        {
          path: '',
          name: 'CompanyInfos',
          component: () => import('@/pages/company/Infos.vue'),
          meta: {
            title: 'Informations générales',
            breadcrumb: 2,
          },
        },
        {
          path: 'users',
          name: 'CompanyUsers',
          component: () => import('@/pages/company/Users.vue'),
          meta: {
            title: 'Utilisateurs',
            breadcrumb: 2,
          },
        },
        {
          path: 'accounts',
          name: 'CompanyAccounts',
          component: () => import('@/pages/company/Accounts.vue'),
          meta: {
            title: 'Comptes API',
            breadcrumb: 2,
          },
        },
      ],
    },
    {
      path: '/create/account',
      name: 'CreateAccount',
      component: () => import('@/pages/creation/Account.vue'),
      meta: {
        level: 3,
        breadcrumb: 1,
        title: 'Création de compte',
      },
    },
    {
      path: '/create/company',
      name: 'CreateCompany',
      component: () => import('@/pages/creation/Company.vue'),
      meta: {
        level: 3,
        breadcrumb: 1,
        title: 'Création de société',
      },
    },
    // 404
    {
      path: '*',
      redirect: '/',
    },
  ],
});

// TODO: check router logic
router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} - Silae API`;
  eventBus.$emit('asyncComponentLoading', to);

  const { instance, accounts } = useMsal();

  // Not authenticated
  if (!store.getters['auth/isLogged'] || store.state.auth.account.expiration - Date.now() < 60) {
    try {
      const data = await instance.acquireTokenSilent({ account: accounts[0] });
      console.debug(data);
      store.dispatch('auth/login', {
        token: data.idToken,
        account: {
          name: data.idTokenClaims.name,
          type: data.idTokenClaims.role,
          id: data.idTokenClaims.accountId,
          mail: data.idTokenClaims.email,
          company: data.idTokenClaims.companyId,
          expiration: data.idTokenClaims.exp * 1000,
        },
      });
    } catch {
      instance.loginRedirect();
    }
  }
  // Authenticated
  // Check if user cannot achieve action to redirect him to home
  if (store.getters['auth/level'] < to.meta.level) {
    if (from.name !== 'Home') {
      store.commit('application/RESET_NAVIGATION');
      next({ name: 'Home' });
    } else {
      document.title = `${from.meta.title} - Silae API`;
      eventBus.$emit('asyncComponentLoaded');
    }
    return;
  }
  store.commit('application/PUSH_NAVIGATION', to);
  next();
});

export default router;
