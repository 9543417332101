import axios from '@/http';

export default {
  /**
   * Method used to get permissions of a token.
   *
   * @returns {Promise} The result in a promise.
   */
  get() {
    return axios.get('/ability-rules');
  },
};
