<template>
  <div v-if="isLogged" id="app">
    <LayoutVue>
      <RouterView />
    </LayoutVue>
  </div>
  <img v-else id="logo" src="@/assets/img/logo.svg" alt="Logo" />
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LayoutVue from './components/Layout.vue';

export default {
  name: 'App',
  components: {
    LayoutVue,
  },
  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged',
    }),
    ...mapActions({
      version: 'application/version',
    }),
  },
};
</script>
<style>
#logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  animation: 2s infinite alternate fade;
}

#app {
  animation: 2s fade;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
