import axios from '@/http';

export default {
  /**
   * Method used to create an API account.
   *
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  create(payload) {
    return axios.post('/api-account', payload);
  },

  /**
   * Method used to get API accounts.
   *
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  get(filter) {
    return axios.post('/api-accounts', filter);
  },

  /**
   * Method used to get Silae API accounts.
   *
   * @param {String} domain The domain id.
   *
   * @returns {Promise} The result in a promise.
   */
  getSilae(domain) {
    return axios.get('/api-accounts', { params: { DomainId: domain } });
  },

  /**
   * Method used to get softwares.
   *
   * @returns {Promise} The result in a promise.
   */
  getSoftwares() {
    return axios.get('/softwares');
  },

  /**
   * Method used to delete API accounts.
   *
   * @param {String} account The account id.
   *
   * @returns {Promise} The result in a promise.
   */
  delete(account) {
    return axios.delete(`/api-accounts/${account}`);
  },

  /**
   * Method used to delete API accounts.
   *
   * @param {String} account The account id.
   *
   * @returns {Promise} The result in a promise.
   */
  hardDelete(account) {
    return axios.delete(`/api-accounts/${account}/hard-delete`);
  },

  /**
   * Method used to get deleted API accounts.
   *
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getDeleted(filter) {
    return axios.post('/deleted-api-accounts', filter);
  },

  /**
   * Method used to delete a subscription.
   *
   * @param {String} subscription The subscription id.
   *
   * @returns {Promise} The result in a promise.
   */
  deleteSubscription(subscription) {
    return axios.delete(`/subscriptions/${subscription}`);
  },
};
