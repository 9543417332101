export const SET_ALL = 'SET_ALL';
export const SET_OFFERS = 'SET_OFFERS';
export const SET_PLATFORMS = 'SET_PLATFORMS';
export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_AGENCIES = 'SET_AGENCIES';
export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SOFTWARES = 'SET_SOFTWARES';
export const SET_DELETED = 'SET_DELETED';
export const NEW_FILTER = 'NEW_FILTER';
