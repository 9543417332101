<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :style="{ 'min-width': width, 'min-height': height }"
    @contextmenu.prevent
  >
    <use :href="`${src}#root`" :width="width" :height="height" />
  </svg>
</template>
<script>
export default {
  name: 'SVGIcon',
  props: {
    src: { type: String, required: true },
    width: { type: Number, required: false, default: null },
    height: { type: Number, required: false, default: null },
  },
};
/*
defineProps(); */
</script>
