/* ============
 * Vuex Store
 * ============
 *
 * The store of the application
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import auth from './modules/auth';
import application from './modules/application';
import apiAccount from './modules/apiAccount';
import account from './modules/account';
import company from './modules/company';
import offer from './modules/offer';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    application,
    apiAccount,
    account,
    company,
    offer,
  },
});

export default store;
